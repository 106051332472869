console.log("Vite ⚡️ Rails")

import "@hotwired/turbo-rails"
import "@fontsource-variable/inter"
import "protos-stimulus"

import "../controllers"

import { install } from "@github/hotkey"

function installHotkeys() {
  for (const el of document.querySelectorAll('[data-hotkey]')) {
    install(el)
  }
}

document.addEventListener('turbo:load', () => {
  installHotkeys()
})

document.addEventListener('turbo:frame-load', () => {
  installHotkeys()
})

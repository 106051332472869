import { Controller as BaseController } from "@hotwired/stimulus"

export class Controller extends BaseController {
  static targets = ["alert"]

  connect() {
    this.alertTarget.classList.add("transition-opacity")

    setTimeout(() => {
      this.element.remove()
    }, 5000)

    setTimeout(() => {
      this.alertTarget.classList.add("opacity-0")
    }, 3000)
  }
}

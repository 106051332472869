import { Autocomplete } from "stimulus-autocomplete"

export class Controller extends Autocomplete {
  static targets = ["inci"]

  connect() {
    super.connect()

    this.handleChange = this.handleChange.bind(this)

    this.element.addEventListener(
      "autocomplete.change",
      this.handleChange
    )
  }

  disconnect() {
    super.disconnect()
    this.element.removeEventListener("autocomplete.change", this.handleChange)
  }

  handleChange(event) {
    const { detail } = event
    this.inciTarget.value = detail.selected.dataset.inci
  }
}
